import React from 'react'
import Navb from '../../../nav/navb'
import './softHome.css'
import ServicesHeader from '../../../headerServices/servicesHeader'
import SoftProductGallery from '../softAnodizingProducts/softProductGallery'
import SoftAppn from '../softAnodizingApplication/softAppn'
import SoftAdv from '../softAnodizingAdv/softAdv'
import Footer from '../../../../footer/footer'
import Scroll from '../../../../scrollToTop/scroll'

const SoftHome = () => {
  return (
    <div className='softAnodizingHome '>
      <div className="softAnodizingHomePage hero">
        <Navb />
        <ServicesHeader
          heading="Soft & Color Anodising"
          content="Soft & color anodizing elevates aluminum for protection, architecture, and aesthetics. SNS Tech specializes in this process, serving domestic and export clients for over a decade."
        />
      </div>
      <section>
      <div className='aboutUS' id='aboutUs'>
      <div className="aboutUsInfo hidden">
        
        <h2>Unlock the potential of soft and color anodizing—an essential technique for protective, architectural, and decorative applications, backed by over a decade of expertise at SNS Tech</h2>
       
      </div>
      </div>
      </section>
      <div className="softAnodizingMain">
        <SoftProductGallery />
        <SoftAppn />
        <SoftAdv />
      </div>
      
      <div className="softAnodizingFooter">
        <Footer />
      </div>
      <Scroll />
    </div>
  )
}

export default SoftHome
