import React from 'react'
import './certificates.css'
import { chakan1, udyam1 } from '../images'

const Certificates = () => {
  return (
    <div className='qualityCertificates' id='certificates'>
      <div className="qualityHeading">
        <h5>Why choose us</h5>
        <h2>Look at our quality assurance certificates</h2>
      </div>
      <div className="certificates">
        <img src={chakan1} alt="Chakan certificate" />
        <img src={udyam1} alt="Udyam certificate" />
      </div>
    </div>
  )
}

export default Certificates
