import React from 'react';
import './clientCard.css';
const ClientCard = ({ clientLogo }) => {
    return (
        <div className='clientCard'>
            <div className="client">
                <img src={clientLogo} alt="client" />
            </div>
        </div>
    )
}

export default ClientCard
