import React from 'react'
import './services.css'
import Card from '../consultancy/cards/card'

const Services = () => {
    return (
        <div className='servicesSection' id='aboutUs'>
            <div className='servicesSection__intro hidden'>
                <h5>What do we provide?</h5>
                <h2>Our Services</h2>
            </div>
            <div className="aboutConsultancy__services">
                <div className="consultancy-hardAnodizing hidden">
                    <Card
                        imgSource="https://img.freepik.com/free-photo/abstract-gray-background-metal-texture-generative-ai_169016-30459.jpg?t=st=1709137792~exp=1709141392~hmac=d9ec6d2fcb0cd32f40b819fa66e68d6eb8295d0ba09c0bc6caa96898a1022e59&w=1060"
                        heading='Anodising'
                        info='Experience the durability and elegance of our aluminum anodizing services, enhancing your metal products with superior corrosion resistance and a refined finish.'
                        title='Learn More'
                        destination='/anodizing'
                    />
                </div>
                <div className="consultancy-softAnodizing hidden">
                    <Card
                        imgSource="https://img.freepik.com/free-photo/large-steel-factory-warehouse_1127-3267.jpg?t=st=1709137486~exp=1709141086~hmac=392406e0eed0de79daf373740ac31f8969b9f4f03be4d0316694bfa1828a0f83&w=900"
                        heading='Passivation / Conversion Coating'
                        info="Transforming metals with precision through our specialized conversion and passivation processes, ensuring durability and quality that lasts."
                        title='Learn More'
                        destination='/conversion_coating'
                    />
                </div>
                <div className="consultancy-chromating hidden">
                    <Card
                        imgSource="https://img.freepik.com/free-photo/friendly-caucasian-afro-american-business-people-shaking-hands_1262-12660.jpg?t=st=1709137986~exp=1709141586~hmac=218377e13009b7e776fd807cc487ef26ac731ddcd3194aee80784740a14035c9&w=900"
                        heading='Consultancy'
                        info="Unlock unparalleled insights and solutions with our consultancy services, tailored to empower your business strategies and drive sustainable growth."
                        title='Learn More'
                        destination='/consultancy'
                    />
                </div>
            </div>
        </div>
    )
}

export default Services
