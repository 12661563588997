import React from 'react'
import './softAppn.css'
import { appn1 } from '../../images'

const SoftAppn = () => {
    return (
        <div className='softApplicationsMain'>
            <div className="softApplicationsHeading hidden">
                <h5>Areas of application</h5>
                <h2>Dive into our varied areas of application, each offering unique insights and opportunities.</h2>
            </div>
            <div className="softApplications hidden">
                <div className="grid">
                    <img src={appn1} alt="applications" />
                </div>
                <div className="gridDetailsSoft">
                    <ul>
                        <li>Architectural & Industrial aluminium profiles</li>
                        <li>Solar panels</li>
                        <li>Breaking System for Automobile</li>
                        <li>Furniture industries</li>
                        <li>Transport – Buses, Metro-rail</li>
                        <li>Fixtures</li>
                        <li>Electric transmission </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SoftAppn
