import React from 'react'
import './clients.css'
import { Essem, SM, alicon, forbes, GE, bharat, integra, knorr } from '../images'
import ClientCard from './clientCard'

const Clients = () => {
    return (
        <div className='clientsMain'>
            <div className="clientsHeading">
                <h5>our clients</h5>
                <h2>We did a great job with these companies. You can be next to work-with.</h2>
            </div>
            <div className="clients">
                <div className="clientsRow">
                    <ClientCard clientLogo={bharat} />
                    <ClientCard clientLogo={forbes} />
                    <ClientCard clientLogo={alicon} />
                    <ClientCard clientLogo={Essem} />
                </div>
                <div className="clientsRow">
                    <ClientCard clientLogo={integra} />
                    <ClientCard clientLogo={GE} />
                    <ClientCard clientLogo={knorr} />
                    <ClientCard clientLogo={SM} />
                </div>

            </div>
        </div>
    )
}

export default Clients
