import React from 'react'
import HardSlider from '../hardSlider/hardSlider'

const HardProductGallery = () => {
  return (
    <div className='productGalleryMain'>
      <div className="productGallery__heading hidden">
        <h5>Our products</h5>
        <h2>Discover our diverse product gallery</h2>
      </div>
      <div className="productGallerySlider hidden">
        <HardSlider />
      </div>
    </div>
  )
}

export default HardProductGallery
