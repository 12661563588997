import React from 'react'
import ConversionProductSlider from './conversionProductSlider'

const ConversionProductGallery = () => {
  return (
    <div className='productGalleryMain' id='aboutUs'>
      <div className="productGallery__heading hidden">
        <h5>Our products</h5>
        <h2>Discover our diverse product gallery</h2>
      </div>
      <div className="productGallerySlider hidden">
        <ConversionProductSlider />
      </div>
    </div>
  )
}

export default ConversionProductGallery
