import React from 'react';
import { hard8 } from '../images'
import YellowBtn from '../../../yellowButton/yellowBtn';
import './aboutAnodizing.css'

const AboutHard = () => {
  return (
    <div className='aboutHard' id='aboutUs'>
      <div className='aboutHard__intro hidden'>
        <h5>About</h5>
        <h2>Hard Anodising</h2>
        <p>Welcome to our world of hard anodizing—where metal surfaces are transformed into resilient shields, boasting enhanced durability and corrosion resistance, ensuring longevity and reliability for your products.</p>
        <YellowBtn title='Know more!' destination='/hard_anodizing' />
      </div>
      <div className="aboutHardImg hidden">
        <img src={hard8} alt="Hard anodizing" />
      </div>
    </div>
  )
}

export default AboutHard
