import React from 'react';
import { Routes, Route } from 'react-router-dom'
import './App.css';
import { Anodizing, Consultancy, ConversionHome, HardHome, Home, SoftHome } from './components';
import ScrollToTop from './components/home/scrollToTop';

function App() {

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/anodizing' element={<Anodizing />} ></Route>
        <Route path='/consultancy' element={<Consultancy />}></Route>
        <Route path='/hard_anodizing' element={<HardHome />}></Route>
        <Route path='/soft_anodizing' element={<SoftHome />}></Route>
        <Route path='/conversion_coating' element={<ConversionHome />}></Route>
      </Routes>
    </div>
  );
}

export default App;
