import React from 'react'
import './about.css'
import Card from '../cards/card'
import { hard8, soft1, conversion3 } from '../images'

const About = () => {
    return (
        <div className='aboutConsultancy' id='aboutUs'>
            <div className='aboutConsultancy__intro hidden'>
                <h5>What do we provide?</h5>
                <h2>We are providing to anodizing & coating supplier for effective, techno commercial solutions</h2>
            </div>
            <div className="aboutConsultancy__services">
                <div className="consultancy-softAnodizing hidden">
                    <Card
                        imgSource={soft1}
                        heading='Soft & Colour Anodising'
                        info='Unlock the potential of soft and color anodizing—an essential technique for protective, architectural, and decorative applications, backed by over a decade of expertise at SNS Tech.'
                        title='Learn More'
                        destination='/soft_anodizing'
                    />
                </div>
                <div className="consultancy-hardAnodizing hidden">
                    <Card
                        imgSource={hard8}
                        heading='Hard Anodising'
                        info='Welcome to our world of hard anodizing—where metal surfaces are transformed into resilient shields, boasting enhanced durability and corrosion resistance, ensuring longevity and reliability for your products.'
                        title='Learn More'
                        destination='/hard_anodizing'
                    />
                </div>
                <div className="consultancy-chromating hidden">
                    <Card
                        imgSource={conversion3}
                        heading='Passivation / Conversion Coating'
                        info="Aluminum chromating, also known as chemical conversion coating, is a surface treatment process that enhances the corrosion resistance and paint adhesion of aluminum and its alloys."
                        title='Learn More'
                        destination='/conversion_coating'
                    />
                </div>
            </div>
        </div>
    )
}

export default About
