import React, { useEffect, useRef, useState } from 'react'
import './scroll.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronUp } from '@fortawesome/free-solid-svg-icons';


const Scroll = () => {
    const toTopRef = useRef();
    const scrollToTop = (eleRef) => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const handleScroll = (event) => {
        if (window.scrollY > 500) {
            setShow(true);
        } else {
            setShow(false);
        }
    };
    const [show, setShow] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show');
                }
            })
        });
        const hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((el) => observer.observe(el));

        return () => {
            hiddenElements.forEach((el) => observer.unobserve(el));
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])
    return (
        <div className='scrollToTop'>
            <div className="scrollToTopIcon">
                {show && <FontAwesomeIcon
                    icon={faCircleChevronUp}
                    size='2xl'
                    style={{ color: '#FFB400' }}
                    onClick={() => scrollToTop(toTopRef)} />}
            </div>
        </div>
    )
}

export default Scroll
