import React from 'react'
import './servicesHeader.css'

const ServicesHeader = ({ heading, content, }) => {
    return (
        <div className="servicesHeader-main hidden">
            <h1>{heading}</h1>
            <p>{content}</p>
        </div>
    )
}

export default ServicesHeader
