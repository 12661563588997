import React, { useEffect, useRef, useState } from 'react'
import './home.css'
import { AboutUs, Appn, Certificates, Footer, Header, Services, Navb, PlantCapacity, Scroll, Clients, } from '../index'


const Home = () => {
  const aboutUsRef = useRef();
  const footerRef = useRef();
  useRef();


  const handleScroll = (event) => {
    if (window.scrollY > 500) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      })
    });
    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((el) => observer.observe(el));

    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])
  return (
    <div className='home' id='home'>
      <div className="hero ">
        <Navb />
        <Header />
      </div>
      <div className="main">
        <section className='aboutUs' ref={aboutUsRef}>
          <AboutUs />
        </section>
        <section className="services-section" >
          <Services />
        </section>
        <section className="facilities" >
          <PlantCapacity />
        </section>
        <section className='applications' >
          <Appn />
        </section>
        <section className='certificatesHome' >
          <Certificates />
        </section>
        <section className='clientsHome' id='clients'>
          <Clients />
        </section>
      </div>
      <div className="footer" ref={footerRef}>
        <section className='footer'>
          <Footer />
        </section>
      </div>
      <Scroll />
    </div>
  )
}

export default Home
