import React from 'react'
import Slider from 'react-animated-slider'
import 'react-animated-slider/build/horizontal.css';
import { soft1, soft2, soft4, soft5, soft6, soft7, soft8, soft9, soft10 } from '../../images'
import './softSlider.css'

const SoftSlider = () => {
	return (
		<div className='softSlider'>
			<Slider autoplay={1500}>
				<img src={soft1} />
				<img src={soft2} />
				<img src={soft4} />
				<img src={soft5} />
				<img src={soft6} />
				<img src={soft7} />
				<img src={soft8} />
				<img src={soft9} />
				<img src={soft10} />
			</Slider>
		</div>
	)
}

export default SoftSlider
