import React from 'react';
import './yellowBtn.css'

const YellowBtn = ({ title, destination }) => {
  const handleClick = () => {
    window.location.href = destination;
  }

  return (
    <div className='yellowBtn'>
      <button onClick={handleClick}>{title}</button>
    </div>
  )
}

export default YellowBtn