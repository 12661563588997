import React from 'react'
import { useNavigate } from 'react-router-dom'
import './footer.css'
import ContactButton from './contactButton'
import { logo } from '../images'

const Footer = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    }
    return (
        <div className='footerData hidden' id='footer'>

            <div className="contactDetails">
                <div className="footerHead">
                    <div className="details-heading">
                        <img src={logo} alt="logo" className='logo' onClick={handleClick} />
                    </div>
                    <div className="mailBtn">
                        <ContactButton />
                    </div>
                </div>
                <div className="companyContactDetails">

                    <div className="section-details">
                        <div className="details-address">
                            <div className="unit1 units">
                                <p><h6>Unit I </h6> Gat No.95, Jyotiba Nagar, Talawade,Near Laxmi Kitchen Equipments, Pune Maharashtra411062</p>
                            </div>
                            <div className="unit2 units">
                                <p><h6>Unit II </h6>Gat No.357/18, Plot No.06, Waghjai Nagar, Kharabwadi, Chakan, Pune Maharashtra410501</p>
                            </div>
                            <div className="gstIn units"><h6>GSTIN </h6>27ACQFS1328H1ZV</div>
                        </div>
                        <div className="details-rest">
                            <div className="contactPersons">
                                
                                <div className="contactNames">
                                    <li>
                                        P.D.Kolambe
                                        <ul>9421038259</ul>
                                        <ul>9156104335</ul>
                                    </li>
                                    <li>
                                        N.E.Sawant
                                        <ul>8830497668</ul>
                                    </li>
                                    <li>
                                        Shashi.M
                                        <ul>9226607906</ul>
                                    </li>
                                </div>
                            </div>
                            <div className="emailAdd">
                                <h6>E-Mail </h6>
                                snstech15@gmail.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="maps">

                <iframe
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=QRGJ+MVQ,%20Biradvadi,%20Maharashtra%20410501+(SNS-Tech)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                    <a href="https://www.gps.ie/">gps systems</a>
                </iframe>
            </div>
        </div >
    )
}

export default Footer
