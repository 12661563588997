import React, { useRef } from 'react';
import Scroll from '../../scrollToTop/scroll.jsx'
import ServicesHeader from '../headerServices/servicesHeader.jsx'
import { AboutHard, AboutSoft, Footer } from './index.js'
import Navb from '../nav/navb.jsx'
import './anodizing.css'

const Anodizing = () => {
  const aboutUsRef = useRef();
  return (
    <div className='service__anodizing'>
      <div className="anodizing__intro hero  hidden">
        <Navb />
        <ServicesHeader
          heading="Anodising"
          content="Experience the durability and elegance of our aluminum anodizing services, enhancing your metal products with superior corrosion resistance and a refined finish."
        />
      </div>
      <div className="anodizing__Info hidden">
        <section className='aboutUs' ref={aboutUsRef}>
          <AboutHard />
          <AboutSoft />
        </section>
      </div>
      <div className="anodizing__Footer">
        <Footer />
      </div>
      <Scroll />
    </div>
  )
}

export default Anodizing
