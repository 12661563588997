import React from 'react'
import './plantCapacity.css'
import { plant } from '../images'

const PlantCapacity = () => {
  return (
    <div className='plantCapacity' id='plantCapacity'>
      <div className="plantCapacityIntro hidden">
        <h5>Our works</h5>
        <h2>Our Facilities</h2>
        <p>At our facility, we boast extensive anodizing and chromating capacities. With advanced technology and a commitment to excellence, we meet diverse project demands with precision and efficiency. Explore our plant and discover the power behind our capabilities.</p>
      </div>
      <div className="plantImg">
        <img src={plant} alt="Our plant" />
      </div>
    </div>
  )
}

export default PlantCapacity
