import React from 'react'
import './softAdv.css'

const SoftAdv = () => {
  return (
    <div className='softAdvantages__main'>
      <div className="softAdv-heading hidden">
        <h2>Advantages of soft & color Anodising</h2>
      </div>
      <div className="softAdvantages">
        <div className="adv hidden">
          <ul>
            <li>Maintenance free</li>
            <li>Excellent Aesthetics</li>
            <li>Anodizing process is providing many colour shed options and excellent life span</li>
          </ul>
        </div>
        <div className="adv hidden">
          <ul>
            <li>Improve durability</li>
            <li>Enhanced life span</li>
            <li>Shed stability</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SoftAdv
