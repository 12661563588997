import React from 'react'
import Slider from 'react-animated-slider'
import 'react-animated-slider/build/horizontal.css';
import { hard1, hard2, hard3, hard5, hard6, hard7, hard8 } from '../../images'

const HardSlider = () => {
  return (
    
    <div className='hardSlider' style={{ width: '50%', height: '50%' }}>
      <Slider autoplay={1500}>
        <img src={hard1} alt='hard anodising product' />
        <img src={hard2} alt='hard anodising product' />
        <img src={hard3} alt='hard anodising product' />
        <img src={hard5} alt='hard anodising product' />
        <img src={hard6} alt='hard anodising product' />
        <img src={hard7} alt='hard anodising product' />
        <img src={hard8} alt='hard anodising product' />
      </Slider>
      
    </div>
    
  )
}

export default HardSlider
