import React from 'react'
import './hardAdv.css'

const HardAdv = () => {
  return (
    <div>
      <div className='hardAdvantages__main'>
        <div className="hardAdv-heading hidden">
          <h2>Advantages of Hard Anodising</h2>
        </div>
        <div className="hardAdvantages">
          <div className="adv hidden">
            <ul>
              <li>Excellent lubricating properties.</li>
              <li>Excellent corrosive and abrasive resistance.</li>
              <li>Normally use for utensils to increase life & save energy for kitchen appliances.</li>
              <li>Excellent wear & tear, malleability and ductility.</li>
            </ul>
          </div>
          <div className="adv hidden">
            <ul>
              <li>Enhancement of part  working life.</li>
              <li>Increase in the surface hardness up to 600HV.</li>
              <li>The hard anodizing surface are non-toxic, and non-reactive with foods. It is thermal-efficient, heats fast and evenly and is not spoiled by high heat. It is tough and durable, will not tarnish or corrode and will stay looking new for years.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HardAdv
