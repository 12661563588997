import React from 'react';
import YellowButton from '../yellowButton/yellowBtn'

class ContactButton extends React.Component {
    render() {
        return (
            <YellowButton title='Contact us' destination={'https://mail.google.com/mail/u/0/#inbox?compose=DmwnWrRnZnRcwFqvzjXQkDdNTbXRdRMdkvJmDldMGLGCMKcDrVwJsJjNkFrvzxpKtpJkrsJWscMq'} />
        );
    }
}

export default ContactButton;