import React from 'react'
import './card.css'
import { useNavigate } from 'react-router-dom'


const Card = ({ imgSource, heading, info, title, destination, }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(destination);
    }
    return (
        <div className='consultancyCard'>
            <div className="consultancyCard-img">
                <img src={imgSource} alt={title} />
            </div>
            <div className="consultancyCard-info">
                <h3>{heading}</h3>
                <p>{info}</p>
                <div className="redirectBtn">
                    <button onClick={handleClick}>{title}</button>
                </div>
            </div>
        </div>
    )
}

export default Card
