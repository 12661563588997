import React from 'react'
import { soft1 } from '../images'
import './aboutAnodizing.css'
import YellowBtn from '../../../yellowButton/yellowBtn';

const AboutSoft = () => {
  return (
    <div className='aboutHard'>
      <div className="aboutHardImg hidden">
        <img src={soft1} alt="Hard anodizing" />
      </div>
      <div className='aboutHard__intro hidden'>
        <h2>Soft & Colour Anodising</h2>
        <p>Unlock the potential of soft and color anodizing—an essential technique for protective, architectural, and decorative applications, backed by over a decade of expertise at SNS Tech.</p>
        <YellowBtn title='Know more!' destination='/soft_anodizing' />
      </div>
    </div>
  )
}

export default AboutSoft
