import React from 'react'
import './conversionHome.css'
import Navb from '../../nav/navb'
import ServicesHeader from '../../headerServices/servicesHeader'
import ConversionProductGallery from './conversionProductGallery/conversionProductGallery'
import Footer from '../../../footer/footer'
import ConversionAppn from './conversionAppn/conversionAppn'
import Scroll from '../../../scrollToTop/scroll'

const ConversionHome = () => {
  return (
    <div className='conversionHome'>
      <div className="conversionHomePage hero ">
        <Navb />
        <ServicesHeader
          heading="Passivation / Conversion Coating"
          content="This process in purely chemical process & providing high level of corrosive resistance of the parts with great aesthetic appearance and enhancing the life span on the parts. In this process creating  fine chemical oxidation layer of the parts with RoHS compliance. This process is use for inside parts which not exposed to weather directly."
        />
      </div>
      <div className="conversionMain">
        <ConversionProductGallery />
        <ConversionAppn />
      </div>
      <div className="conversionFooter">
        <Footer />
      </div>
      <Scroll />
    </div>
  )
}

export default ConversionHome
