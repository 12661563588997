import React from 'react'
import './hardHome.css'
import Navb from '../../../nav/navb'
import ServiceHeader from '../../../headerServices/servicesHeader'
import HardProductGallery from '../hardProductGallery/hardProductGallery'
import HardAppn from '../hardAppn/hardAppn'
import Footer from '../../../../footer/footer'
import HardAdv from '../hardAdv/hardAdv'
import Scroll from '../../../../scrollToTop/scroll'

const HardHome = () => {
  return (
    <div className='hardAnodizingHome '>
      <div className="hardAnodizingHomePage hero">
        <Navb />
        <ServiceHeader
          heading="Hard Anodising"
          content="Hard anodizing boosts metal durability and corrosion resistance. SNS Tech applies precise electro-chemical processes, creating layers from 30 to 150 microns, with color shades tied to coating thickness."
        />
      </div>
      <div>
      <div className='aboutUS' id='aboutUs'>
      <div className="aboutUsInfo hidden">
        
        <h2>Welcome to our world of hard anodizing—where metal surfaces are transformed into resilient shields, boasting enhanced durability and corrosion resistance, ensuring longevity and reliability for your products</h2>
       
      </div>

    </div>
      </div>
      <div className="hardAnodizingMain" id='aboutUs'>
        <HardProductGallery />
        <HardAppn />
        <HardAdv />
      </div>
      <div className="hardAnodizingFooter">
        <Footer />
      </div>
      <Scroll />
    </div>
  )
}

export default HardHome
