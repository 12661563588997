import React from 'react';
import { appn1, appn2 } from '../images'
import './appn.css'

const Appn = () => {
    return (
        <div className='applications' id='applications'>
            <div className="applicationsHeading">
                <h5>Areas of applications</h5>
                <h2>Dive into our varied areas of application, each offering unique insights and opportunities.</h2>
            </div>
            <div className="applicationsField">
                <div className="appn-left">
                    <div className="grid">
                        <img src={appn1} alt="applications" />
                    </div>
                    <div className="gridDetails">
                        <ul>
                            <li>Railways-Metro </li>
                            <li>Engineering</li>
                            <li>Defence</li>
                            <li>Communication-5G</li>
                            <li>Heavy Engineering</li>
                        </ul>
                    </div>
                </div>
                <div className="appn-right">
                    <div className="gridDetails">
                        <ul>
                            <li>Automobile ( High Silicon Alloy)</li>
                            <li>Health Care </li>
                            <li>Renewal Energy (Solar)</li>
                            <li>Electronics</li>
                            <li>Breaking System</li>
                        </ul>
                    </div>
                    <div className="grid">
                        <img src={appn2} alt="applications" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Appn
