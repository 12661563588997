import React from 'react'
import './consultancy.css'
import Navb from '../nav/navb'
import ServicesHeader from '../headerServices/servicesHeader'
import About from './about/about'
import Footer from '../../footer/footer'
import Clients from '../../clients/clients'
import Scroll from '../../scrollToTop/scroll'

const Consultancy = () => {
    return (
        <div className='consultancy__page '>
            <div className="consultancy__Main hero">
                <Navb />
                <ServicesHeader
                    heading="Consultancy Services"
                    content="We are also provide consultation to our customer to improve anodizing & conversion coating applicability. We are involving with our customer from development stage to fix the process flow to improve overall finish."
                />
            </div>
            <div className="consultancy__about">
                <About />
            </div>
            <div className="consultancy__clients" id='clients'>
                <Clients />
            </div>
            <div className="consultancy__footer">
                <Footer />
            </div>
            <Scroll />
        </div>
    )
}

export default Consultancy
