import React from 'react'
import './conversionAppn.css'

const ConversionAppn = () => {
    return (
        <div className='conversionApplicationsMain'>
            <div className="conversionApplicationsHeading hidden">
                <h5>Areas of application & advantages</h5>
                <h2>Dive into our varied areas of application & advantages, each offering unique insights and opportunities.</h2>
            </div>
            <div className="conversionApplications">
                <div className="converAppn hidden">
                    <ul>
                        <li>Hexavalent chromium free Passivation for aluminium</li>
                        <li>Suitable as post-treatment of anodic coatings</li>
                        <li>Suitable as conversion coating of magnesium</li>
                        <li>Liquid concentrate, based on trivalent chromium</li>
                        <li>Also works on alloyed and casted aluminium</li>
                        <li>Suitable as Pre-treatment before lacquering, powder coating and gluing and approved by GSB and QUALICOAT</li>
                        <li>Low electrical resistance</li>
                        <li>Excellent bare corrosion protection comparable to Hexavalent Passivation</li>
                    </ul>
                </div>
            </div>
        </div >
    )
}

export default ConversionAppn
