import React from 'react';
import './hardAppn.css'
import { appn1, appn2 } from '../../images';

const HardAppn = () => {
    return (
        <div className='hardApplicationsMain'>
            <div className="hardApplicationsHeading hidden">
                <h5>Areas of application</h5>
                <h2>Dive into our varied areas of application, each offering unique insights and opportunities.</h2>
            </div>
            <div className="hardApplications">
                <div className="hardAppln-left hidden">
                    <div className="grid">
                        <img src={appn1} alt="application" />
                    </div>
                    <div className="gridDetails">
                        <ul>
                            <li>Battery Box</li>
                            <li>Aluminium Cylinders</li>
                            <li>All Pneumatic parts like-FRL body, Valve Body, Pump Body</li>
                            <li>Structural Parts</li>
                            <li>Automobile parts like- Heat-sink, Skid plate, covers</li>
                            <li>Healthcare equipment’s</li>
                        </ul>
                    </div>
                </div>
                <div className="hardAppln-right hidden">
                    <div className="gridDetails">
                        <ul>
                            <li>Food industry</li>
                            <li>Utensils</li>
                            <li>Pistons</li>
                            <li>Actuators</li>
                            <li>Engine Blocks</li>
                            <li>Breaking system parts</li>
                        </ul>
                    </div>
                    <div className="grid">
                        <img src={appn2} alt="application" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HardAppn
