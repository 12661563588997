import React from 'react';
import './header.css'
import HeaderCarousel from './headerCarousel';

const Header = () => {
  return (
    <HeaderCarousel />
  )
}

export default Header
