import React from 'react';
import Slider from 'react-animated-slider'
import 'react-animated-slider/build/horizontal.css';
import { conversion3, conversion4, conversion5, conversion6, conversion7, conversion8, conversion9 } from '../images'

const ConversionProductSlider = () => {
	return (
		<div className='softSlider'>
			<Slider duration={2000} autoplay={3000} >
				<img src={conversion3} />
				<img src={conversion4} />
				<img src={conversion5} />
				<img src={conversion6} />
				<img src={conversion7} />
				<img src={conversion8} />
				<img src={conversion9} />
			</Slider>
		</div>
	)
}

export default ConversionProductSlider
