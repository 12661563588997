import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { anodisingBg, consultancyBg, conversionBg, plantBg } from '../images'
import 'bootstrap/dist/css/bootstrap.min.css';


const HeaderCarousel = () => {
    return (
        <Carousel style={{ height: '100%', width: '100%' }}>
            <Carousel.Item interval={2000}>
                <img
                    className="d-block w-100"
                    style={{ height: '100vh', backgroundSize: 'cover' }}
                    src={plantBg}
                    alt="SNS-Tech"
                />
                <Carousel.Caption>
                    <h1>SNS-Tech</h1>
                    <p>Welcome to SNS. We specialize in aluminum anodizing and chromating, enhancing durability and aesthetics. Explore our expertise and elevate your aluminum solutions with us.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    style={{ height: '100vh', backgroundSize: 'cover' }}
                    className="d-block w-100"
                    src={anodisingBg}
                    alt="Anodising"
                />
                <Carousel.Caption>
                    <h1>Anodising</h1>
                    <p>Experience the durability and elegance of our aluminum anodizing services, enhancing your metal products with superior corrosion resistance and a refined finish.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    style={{ height: '100vh', backgroundSize: 'cover' }}
                    className="d-block w-100"
                    src={conversionBg}
                    alt="Passivation"
                />
                <Carousel.Caption>
                    <h1>Conversion & Passivation</h1>
                    <p>
                        Transforming metals with precision through our specialized conversion and passivation processes, ensuring durability and quality that lasts.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
                <img
                    style={{ height: '100vh', backgroundSize: 'cover' }}
                    className="d-block w-100"
                    src={consultancyBg}
                    alt="Consultancy"
                />
                <Carousel.Caption>
                    <h1>Consultancy</h1>
                    <p>
                        Unlock unparalleled insights and solutions with our consultancy services, tailored to empower your business strategies and drive sustainable growth.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}

export default HeaderCarousel
