import React from 'react'
import './aboutUs.css'

const AboutUs = () => {
  return (
    <div className='aboutUS' id='aboutUs'>
      <div className="aboutUsInfo hidden">
      <br>
        </br>
        <br>
        </br>
        <br>
        </br>
        <br>
        </br>
        <br>
        </br>
        <br>
        </br>
        <br>
        </br>
        <br>
        </br>
        <h5>About us</h5>
        <h2>Exploring Vision, Embracing Impact: Our Story Behind the Scenes.</h2>
        <p>We are introduce ourselves as a professional & active in serving the industry in surface coating field. Our track records in the industry as competitive & prompt delivery from last 10 years. Company has depth of knowledge and experience in Anodizing & Conversation coating-Passivation (Trivalent-RoHS) techniques. We are aiming further for competitive in the market with innovative ideas. SNS Tech Industries have always been update towards new technology to serve our customer with competitive & effectively. </p>
        <br>
        </br>
        <h5>Vision</h5>
        <p>Our vision is to lead in all types of premium surface coating services, offering innovative coating solutions tailored to diverse needs. Committed to trust and integrity, we aim to build lasting partnerships and positively impact industries, crafting a future where surfaces reflect our expertise and shared values.</p>
        <br></br>
        <h5>Mission</h5>
        <p>Our mission is to become the preferred supplier of anodizing and conversion coating solutions by prioritizing customer satisfaction and exceeding their expectations. Through transparent communication, reliability, and consistent quality, we ensure that every interaction reflects our dedication to customer-centricity and superior service.</p>
        <br></br>
        <h5>Core value</h5>
        <p> Zero customer complaints.</p>
        <p>On-time delivery.</p>
        <p>Agile and adaptable to ever-changing technology and customer requirements to become competitive</p>
        <p>Trust is the foundation of our relationships with customers.</p>
      </div>
      
    </div>
  )
}

export default AboutUs
