import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './navb.css'
import { Container, Nav, Navbar, Row, Col, NavDropdown } from 'react-bootstrap';
import { DropDownMenu } from './Dropdownmenu';
import { logo } from '../../images';

const Navb = () => {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div>
            <Navbar className="navb" bg='light ' expand='lg' variant='light'>
                <Container>
                    <Navbar.Brand className='nav-brand' href='/'>
                        <img src={logo} alt="logo" className='logo' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls='basic-navbar-nav' />
                    <Navbar.Collapse id='basic-navbar-nav'>
                        <Nav className='ms-auto'>
                            <Nav.Link href='/'>Home</Nav.Link>
                            <Nav.Link onClick={() => scrollToSection('aboutUs')}>About us</Nav.Link>
                            <DropDownMenu title="Services" id="collasible-nac-dropdown">
                                <NavDropdown.Item href="/soft_anodizing" >Soft & Colour Anodising</NavDropdown.Item>
                                <NavDropdown.Item href="/hard_anodizing" >Hard Anodising</NavDropdown.Item>
                                <NavDropdown.Item href="/conversion_coating" >Passivation / Conversion Coating</NavDropdown.Item>
                                <NavDropdown.Item href="/consultancy" >Consultancy</NavDropdown.Item>
                            </DropDownMenu>
                            <Nav.Link onClick={() => scrollToSection('footer')}>Contact us</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </Navbar>
        </div>
    )
}

export default Navb;
